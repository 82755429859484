import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { SentryDns } from './constants'

export const dsn =
    'https://d3463ceebf9c4faa94ad46e12124a8b4@o1290599.ingest.sentry.io/4504518963691520'

export enum Environments {
    local = 'local',
    dev = 'dev',
    acc = 'acc',
    prod = 'prod',
    nsva = 'nsva',
    collaborate = 'collaborate',
}

export const getEnvironment = (): Environments => {
    if (window.location.host.includes('.dev.')) {
        return Environments.dev
    }
    if (window.location.host.includes('.acc.')) {
        return Environments.acc
    }
    if (window.location.host.includes('localhost')) {
        return Environments.local
    }
    if (window.location.host.includes('nsva.se')) {
        return Environments.nsva
    }
    if (window.location.host.includes('collaboratewater.ai')) {
        return Environments.collaborate
    }
    return Environments.prod
}

export const initSentry = () => {
    const environment = getEnvironment()
    if (
        environment === Environments.dev ||
        environment === Environments.prod ||
        environment === Environments.acc
    ) {
        Sentry.init({
            dsn: SentryDns,
            integrations: [new BrowserTracing()],
            environment,
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.1,
        })
    }
}

type LocalServices = {
    [k: string]: string
}

export const localServices: LocalServices = {
    'auth-manager':
        process.env.REACT_APP_AUTH_MANAGER_HOST || 'http://localhost:8022',
    assets: process.env.REACT_APP_ASSET_MODULE_URL || 'http://localhost:8000',
    inspections:
        process.env.REACT_APP_INSPECTION_MODULE_URL || 'http://localhost:8010',
    'inspection-manager-av':
        process.env.REACT_APP_INSPECTION_MANAGER_HOST ||
        'http://localhost:8015',
    'asset-manager-av':
        process.env.REACT_APP_ASSET_MANAGER_HOST || 'http://localhost:8005',
    ingestions:
        process.env.REACT_APP_INGESTION_MODULE_URL || 'http://localhost:3000',
    annotation:
        process.env.REACT_APP_ANNOTATION_MODULE_URL || 'http://localhost:3001',
    'workflow-manager':
        process.env.REACT_APP_WORKFLOW_MANAGER_HOST || 'http://localhost:8007',
    'annotation-manager-av':
        process.env.REACT_APP_ANNOTATION_MANAGER_HOST ||
        'http://localhost:8011',
    'label-manager':
        process.env.REACT_APP_LABEL_MANAGER_HOST || 'http://localhost:8019',
}
