import React from 'react'
import ReactDOM from 'react-dom'
import { onServiceWorkerUpdate } from '@3m1/service-worker-updater'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './index.css'
import { initSentry } from './app/config'
import '@aws-amplify/ui-react/styles.css'
import 'react-toastify/dist/ReactToastify.css'

initSentry()

ReactDOM.render(
    <React.StrictMode>
        <div>
            <p>Sorry, this application cannot be used on its own.</p>
        </div>
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: onServiceWorkerUpdate,
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
